<template>
  <div class="user-todos">
    <p class="has-text-weight-bold is-size-4">{{ $t("af:my_todos.title") }}</p>

    <b-tabs v-model="activeTab">
      <b-tab-item :label="$t('af:my_todos.tab.active')" />
      <b-tab-item :label="$t('af:my_todos.tab.previous')" />
    </b-tabs>

    <template v-if="activeTab === 0">
      <user-active-todos />
    </template>

    <template v-if="activeTab === 1">
      <user-previous-todos />
    </template>
  </div>
</template>

<script>
export default {
  name: "UserTodos",
  title: "af:page.my_todos.title",
  components: {
    UserActiveTodos: () =>
      import("@/components/user/todos/UserActiveTodos.vue"),
    UserPreviousTodos: () =>
      import("@/components/user/todos/UserPreviousTodos.vue"),
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  computed: {},
  async mounted() {
    await this.$store.dispatch("myTodo/fetchCount");
    await this.$store.dispatch("myTodo/fetchMortgageCount");
  },
  methods: {},
};
</script>

<style lang="scss">
.user-todos {
  .b-tabs .tab-content {
    display: none !important;
  }
}
</style>
